@import '../global/imports';

section.tileswithimage {
  .tiles-row {
    margin: -15px;
    .tile {
      padding: 15px;

      a {
        text-decoration: none;
        text-align: center;
      }
      figure {
        transition: 0.2s all cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      a:hover {
        figure {
          border-color: $gray-600 !important;
        }
      }
    }
  }
}