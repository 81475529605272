@import '../global/imports';

section.tiles {
  .mb {
    margin-bottom: $grid-gutter-width;
  }
  a.tile {
    padding-right:35px !important;
  }

  .tile {
    background: $gray-100;
    position: relative;
    color: inherit;
    text-decoration: none;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background-color: $success;
    }
    .title {
      position: relative;
    }

    .readmore {
      position: relative;
      padding-top: 10px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: bold;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1) all;

      &:hover {
        transform: translateX(10px);
      }
      .fa {
        color: $success;
        margin-right: 5px;
        font-size: 1rem;
      }
    }
    &.dark {
      background: lighten($dark, 5%);
      color: $light;
    }

    .title {
      margin: 0;

      & + .content {
        margin-top: 10px ;
      }
    }
  }
}
