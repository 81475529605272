section.gallery {
  .section-inner {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -5px;

    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }
    .images {
      margin: -5px;
      display: flex;
      flex-flow: row wrap;
    }
    figure {
      padding: 5px;

      a {
        display: block;
      }

      img {
        transform: scale(1);
        transition: 0.6s transform cubic-bezier(0.215, 0.610, 0.355, 1);
      }
      
      &:hover {
        img {
          transition: 0.8s transform cubic-bezier(0.215, 0.610, 0.355, 1);
          transform: scale(1.05);
        }
      }
    }

  }
}