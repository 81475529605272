@import "global/imports";


#cover {
  position: relative;
  top: 0;
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  // height: 30vh;

  // @include media-breakpoint-up(md) {
  //   min-height: 450px;
  // }

  // @include media-breakpoint-up(sm) {
  //   @include orientation(portrait) {
  //     height: 40vh;
  //   }
  //   @include orientation(landscape) {
  //     height: 45vh;
  //   }
  // }
  // @include media-breakpoint-up(xl) {
  //   @include orientation(landscape) {
  //     height: 60vh;
  //   }
  // }

  
  figure {
    position: relative;
    z-index: 1;
    // height: 100%;
    width: 100%;
    max-width: 1200px;

    img {
      
    }
  }
}
