
/* assistant-regular - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Assistant/assistant-v2-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Assistant'), local('Assistant-Regular'),
       url('/assets/fonts/Assistant/assistant-v2-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Assistant/assistant-v2-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/Assistant/assistant-v2-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Assistant/assistant-v2-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/Assistant/assistant-v2-latin-regular.svg#Assistant') format('svg'); /* Legacy iOS */
}
/* assistant-600 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/Assistant/assistant-v2-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Assistant SemiBold'), local('Assistant-SemiBold'),
       url('/assets/fonts/Assistant/assistant-v2-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Assistant/assistant-v2-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/Assistant/assistant-v2-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Assistant/assistant-v2-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/Assistant/assistant-v2-latin-600.svg#Assistant') format('svg'); /* Legacy iOS */
}


/* bitter-regular - latin */
@font-face {
  font-family: 'Bitter';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Bitter/bitter-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/assets/fonts/Bitter/bitter-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/Bitter/bitter-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/Bitter/bitter-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/Bitter/bitter-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/Bitter/bitter-v16-latin-regular.svg#Bitter') format('svg'); /* Legacy iOS */
}