@import '../global/imports';

section.slider {
  
  .section-inner {
    margin: 0 -5px;
    
    figure {
      padding: 5px;
      
      a {
        display: block;
      }
    }
  }
  
  .swiper-container {
    padding-bottom: 15px;
  }
  
  .swiper-controls {
    --swiper-theme-color: $primary;
    > div {
      width: var(--swiper-navigation-size);
      height: var(--swiper-navigation-size);
      background: rgba($white, .5);
    }
  }
  .swiper-pagination {
    bottom: 0;
    top: auto;
    text-align: center;
    line-height: 0;
  }
  .swiper-pagination-bullet {
  }
  .swiper-pagination-bullet-active {
    background: $primary;
  }
}