@import "../global/imports";


section.quote {
  .section-inner {
  }
  
  
  blockquote {
    background: $gray-100;
    margin: 0;
    padding: 25px;
    font-size: 20px;
    font-weight: 300;

    @include media-breakpoint-up(lg) {
      padding: 50px;
    }

    .author {
      font-size: 0.75em;
      font-weight: bold;
    }
  }
}
