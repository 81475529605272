@import '../global/imports';

section.list {
  .section-inner {
    ul.list {
      margin: 0;
      padding: 0 0 0 17px;
      list-style: none;

      >li {
        position: relative;
      }

      strong {
        position: relative;
        top: -4px;
      }

      .fa {
        position: absolute;
        left: -16px;
        top: 4px;
        color: $primary;
      }
    }
  }
}
