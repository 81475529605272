@import "../global/imports";

#contact {

  .map {
    position: relative;
    padding-top: 40%;

    iframe {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }
  section a {
    font-weight: bold;
    color: currentColor;
  }
}


#contactform {
  padding-bottom: 20px;

  input[type=text],
  input[type=email],
  textarea {
    -webkit-appearance: none;
    font-size: 16px;
    min-width: 280px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  input[type=submit] {
    border: 0 none;
    background-color: $primary;
    border-radius: 3px;
    padding: 10px 25px;
  }

  .uniform__potty {
    display: none;
  }

  .field-wrapper {
    width: 100%;
    margin-bottom: 15px;

    &.inline {
      align-items: center;
      
      @include media-breakpoint-up(md) {
        display: flex;
      }

      label {
        flex: 1;
        padding-right: 10px;
      }

      .field {
        flex: 3;
      }
    }

  }

  label {
    padding: 7px 0;
    display: inline-block;
    font-weight: 400;
    margin: 0;
  }

  textarea {
    min-height: 120px;
  }

  abbr {
    text-decoration: none;
  }

  .form-actions {
  }

  input[type="submit"] {
    max-width: 130px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
    -webkit-appearance: none;
    appearance: none;
    background: $link-color;
    border: 1px solid darken($link-color, 10%);

    &:hover {
      background: $link-hover-color;
      border: 1px solid darken($link-hover-color, 10%);
    }
  }


  .message {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 8px;

    &.error {
      color: $white;
      background: darken($red, 10%);
    }

    &.success {
      color: $white;
      background: darken($green, 10%);
    }
  }
}

.grecaptcha-badge {
  display: none;
}