@import "global/imports";

$hh: 70px;

.page {
  flex: 1 0 auto;
  overflow: hidden;
  
}

@include media-breakpoint-down(md) {
  .page-wrapper {
    padding-top: $hh;
  }
  .page-wrapper, #header {
    transition: 0.4s transform cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .show-menu {
    .page-wrapper, #header {
      transform: translateX(-320px);
    }
  }
}

#header {
  z-index: 100;
  position: relative;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background-color: $body-bg;
  transition: 0.4s transform cubic-bezier(0.075, 0.82, 0.165, 1);
  // background-color: $gray-200;

  @include media-breakpoint-down(md) {
    position: fixed;
  }

  .nav-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: $hh;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;
      width: 100%;
      height: 50px;
      background: $body-bg;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 40px;
      background: url(../img/drop-shadow.png) no-repeat top center;
      background-size: 100% 40px;
      opacity: 0.1;
      bottom: -20px;
    }
  }

  .navbar {
    display: block;

    @include media-breakpoint-down(md) {
      background-color: $white;
      position: fixed;
      right: 0;
      height: 0;
      width: 100vh;
      overflow: hidden;
      transform: translateX(320px);
      transition: 0.4s transform cubic-bezier(0.075, 0.82, 0.165, 1);

      &.show {
        height: 100vh;
        width: 320px;
        overflow: auto;
      }
    }

    .navbar-collapse {
      @include media-breakpoint-up(md) {
        height: auto;
      }
    }
  }
}

.top-row {
  position: relative;

  @include media-breakpoint-down(md) {
    height: $hh;
  }
}
.navbar-brand {
  img {
    position: relative;
    z-index: 1;
    display: block;
  
    @include media-breakpoint-down(md) {
      height: 40px;
      width: auto;
    }
  }
}

.brand {
  color: $black;
  text-decoration: none;
  // font-family: $font-family-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;

  strong {
    font-size: 1.2em;
  }
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 24px;
  }

  &:hover {
    text-decoration: none;
  }
}



.toggler {
  border: 0 none;
  outline: none;
  padding: 10px 0 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  .toggler-box {
    width: 22px;
    height: 16px;
    display: inline-block;
    position: relative;

    .toggler-inner {
      display: block;
      top: 50%;
    }
  }

  .toggler-inner,
  .toggler-inner::before,
  .toggler-inner::after {
    width: 22px;
    height: 2px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  .toggler-inner::before,
  .toggler-inner::after {
    content: "";
    display: block;
  }

  .toggler-inner::before {
    top: -6px;
  }

  .toggler-inner::after {
    bottom: -6px;
  }
}

/*
   * Squeeze
   */
.toggler--squeeze {
  .toggler-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }

    &::after {
      transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active .toggler-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }

    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease,
        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
