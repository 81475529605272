@import "../global/imports";

#home {
  .teasers {
    .row {
      margin: -5px;

      .col {
        padding: 5px;
      }
    }

    .teaser {
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}
