#menu--main {
  font-size: 15px;

  ul.languages {
    li {
      a {
        text-transform: uppercase;
        padding-right: 5px;
        padding-left: 5px;

        &.disabled {
          text-decoration: line-through;
        }

        &.active {
          font-weight: bold;
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding: 0 25px;
      text-transform: uppercase;
    }

    @include media-breakpoint-up(lg) {
      position: fixed;
      right: 10px;
      top: 0;
    }
  }

  ul.navbar-nav {
    margin: 0;
    padding: 0;

    li {
      text-transform: uppercase;
      list-style: none;
    }

    >li {
      position: relative;

      >a,
      >label {
        cursor: pointer;
        text-decoration: none;
        display: block;
        font-weight: 400;
        line-height: 1em;
        font-weight: bold;
      }

      >span {
        cursor: default;
      }
    }

    .active-trail>a,
    .active-trail>label,
    .is-active {
      color: $dark;
    }

    input.drop {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    ul.navbar-nav {
      overflow: hidden;
      padding: 30px;
      height: 100%;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;

      a,
      label {
        padding: 15px 0;
        display: block;
        color: $black;
        background: $white;
      }

      >.nav-item {
        margin-bottom: -1px;
        border-top: 1px solid $gray-100;

        .nav-link {
          position: relative;

          span {
            position: relative;
            z-index: 1;
          }

          &:after {
            content: "";
            background: $primary;
            display: block;
            position: absolute;
            width: 0%;
            height: 100%;
            opacity: 0;
            transition: all 0.4s;
            z-index: 0;
            top: 0;
          }

          &:hover:after, &:focus:after {
            opacity: 0.4;
            width: 100%;
          }
        }
      }

      .nav-item.expanded {
        >a {
          display: none;
        }

        .sub-menu {
          padding: 0;
          margin: 0;
          padding: 0;
          max-height: 0;
          overflow: hidden;
          transition: 0.4s all cubic-bezier(0.075, 0.82, 0.165, 1);
          border-top: 1px solid $gray-100;

          >li {
            list-style: none;
            border-top: 1px solid #fff;
            padding: 0;
            margin: 5px 0;

            >a {
              white-space: nowrap;
              display: block;
              padding: 15px;
            }
          }
        }
      }

      input.drop:checked+.sub-menu {
        max-height: 2000px;
        transition: 0.4s all cubic-bezier(0.6, 0.04, 0.98, 0.335);

      }
    }
  }

  @include media-breakpoint-up(lg) {
    ul.navbar-nav {
      width: 100%;
      justify-content: flex-end;

      >li:first-child {
        padding-left: 0;
      }

      >li {

        &:first-child,
        &:first-child>.nav-link {
          padding-left: 0 !important;
        }

        &:last-child>.nav-link {
          padding-right: 0 !important;
        }

        &:hover,
        &:focus {
          >.nav-link {
            color: $black !important;

            span:after {
              width: 100%;
              opacity: 1;
            }
          }
        }

        >.nav-link {
          color: $dark;
          padding-bottom: 0;

          &.active span:after {
            width: 100%;
            opacity: 1;
          }

          span {
            position: relative;
            padding-bottom: 15px;
            display: block;

            &:after {
              content: '';
              overflow: hidden;
              position: absolute;
              left: 50%;
              bottom: 0;
              display: block;
              width: 0;
              height: 4px;
              transform: translateX(-50%);
              opacity: 0;
              background-color: $gray-400;
              transition: all .3s cubic-bezier(.175, .885, .32, 1.075);
            }
          }
        }
      }
    }
  }

  #menu-toggle:checked+ul.menu,
  #menu-toggle:checked+ul.menu+ul.menu {
    max-height: 2000px;
  }
}
