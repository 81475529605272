
.btn {
  text-transform: uppercase;
  font-weight: 600;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-sm, $btn-line-height, $btn-border-radius);
}
.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}
.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-base, $btn-line-height-lg, $btn-border-radius-lg);
}

.cta-button {
  @include button-size(.7rem, 2.5rem, $font-size-sm, $btn-line-height, 50px);
}

input {
  font-size: 16px;
}

