@import "global/fonts";

@import "global/imports";
@import "bootstrap/bootstrap";

@import "animate/animate.scss";


@import "components/typography";
@import "components/header";
@import "components/cover";
@import "components/menu";
@import "components/forms";


html {
  height: 100%;
}

body {
  position: relative;
  height: 100%;

  &.no-scroll {
    position: absolute;
    overflow: hidden;
    height: 100vh;
  }
}

.page-wrapper {
  min-height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-flow: column;
  min-height: 100%;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {

  @include media-breakpoint-up(md) {
    padding-right: 30px;
    padding-left: 30px;
  }
}

#footer {
  font-size: 15px;
  position: relative;
  z-index: 1;

  .navbar {
    padding: 0;
  }

  .nav-link {
    font-size: 0.9rem;
  }

  .social {
    i {
      width: 20px;
      display: inline-block;
    }
  }

  a {
    font-weight: bold;
    color: currentColor;
  }
}

.alert {
  padding-top: 30px;
  padding-bottom: 30px;

  a {
    font-weight: bold;
    color: currentColor;
  }
}

.img-fluid {
  width: 100%;
  height: auto;
  max-width: initial;
}

.page {
  flex: 1 0 auto;
  overflow: hidden;
  padding-bottom: 30px;
}

main {
  overflow: hidden;
  @include fadeIn();
}

.h100 {
  height: 100%;
}

figure {
  margin: 0;
  position: relative;

  a[data-fancybox] {
    position: relative;
    display: block;

    &:after {
      content: "\f067";
      display: flex;
      color: $white;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      left: 0;
      top: 0;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      background: $primary;
      opacity: 0;
      transition: 0.3s opacity cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:hover:after {
      display: flex;
      opacity: 1;
    }
  }
}

figure.image-wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: auto;
    transition: 1s opacity ease;
    object-fit: cover;
    display: block;
  }
}

button:focus {
  outline: none;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  @include fadeIn();
}

.intro {
  .intro-image {
    background: $gray-100;

    img {
      max-width: 300px;
      max-height: 200px;
    }
  }

}


// .stacktable {
//   width: 100%;
//   font-size: 1.2rem;
//   background-color: $gray-100;
//   border-radius: $border-radius;
//   overflow: hidden;
//   overflow-x: auto;
//   margin-bottom: 2em;

//   thead,
//   .st-head-row {
//     font-weight: bold;
//     font-size: 1.5rem;
//     border-bottom: 3px solid $body-bg;
//   }

//   th,
//   td {
//     padding: 10px 15px;
//     text-align: left;
//   }

//   td {
//     border-bottom: 1px solid $body-bg;
//   }
// }

.stacktable {
  .st-key {
    text-align: left;
    font-weight: 700;
  }

  &.small-only {

    th:empty,
    td:empty {
      display: none;
      ;
    }
  }
}

/*=============================================
=            Overlay Styles            =
=============================================*/

.fancybox-bg {
  background: #000;
}

.fancybox-is-open .fancybox-bg {
  opacity: 1;
}

.blocks {
  section {
    margin-top: 50px;

    a {
      font-weight: bold;
      color: currentColor;
    }
  }
}

video,
iframe {
  width: 100%;
  height: auto;
}

/*=============================================
=            Breadcrumbs            =
=============================================*/
.breadcrumbs {
  padding: 15px 0;

  ul {
    padding: 0;
    margin: 0;
    color: $gray-400;
    list-style: none;
    display: flex;
    justify-content: flex-start;

    li {
      padding-right: 10px;

      &:after {
        content: ">";
        display: inline-block;
        padding-left: 7px;
      }

      &:last-child:after {
        content: none;
      }

      color: $gray-400;

      a {
        color: $gray-400;
      }
    }
  }
}

.away {
    position: fixed;
    z-index: 1000;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
  

  .alert {
    max-width: 650px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    border-radius: 0;
    position: relative;
    border: 0 none;
    padding-left: 30px;

    .marker {
      width: 5px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
